import React, {Component} from "react";
import TemplateMain from '../template/TemplateMain';
import './Location.css';
import carImage from '../../images/car.svg';
import utensilsImage from '../../images/utensils.svg';
import splashBackground from '../../images/splashpage.svg'

class Location extends React.Component {
  render (){
    return <div className="location">
          <TemplateMain data={templateInfo}/>
    </div>
  }
}
const foodOptionList = [
"Panda Express",
"Jamba Juice",
"Wolfgang Puck Express",
"Carl’s Jr.",
"Rubio’s",
"Veggie Grill"];
const foodOptionListTwo = [
"Taco Bell",
"Lollicup",
"Wetzel’s Pretzels",
"Bruin Buzz Coffee",
"Curbside",
"Greenhouse"
];



const templateInfo = {
  title: "Location",
  renderContent: ()=>{ return <div className="templatemain-right">
    <div className="templatemain-map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3304.9645723005865!2d-118.44633378602958!3d34.07042242422178!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2bc88c3de6ee7%3A0x62f97fe423993f80!2sUCLA%20Ackerman%20Union!5e0!3m2!1sen!2sus!4v1571626613243!5m2!1sen!2sus" width="100%" height="350" frameborder="0" style={{"border":"0"}}
     allowfullscreen=""></iframe>
     </div>
    </div>

    },
  renderContentBottom: ()=>{
    return <div className="templatemain-content">
            <div className="templatemain-title"></div>
            <div className="templatemain-info">

              <div className="" style={{"textAlign":"center", "margin": "0 auto"}}>
              <strong>Address</strong>: 308 Westwood Plaza, Los Angeles, CA 90024
              <br/>
              Ackerman Grand Ballroom, 2nd floor
              <br/>
              Directions By Google Maps <a style={{color: "red" }}  href="https://www.google.com/maps?ll=34.070418,-118.444145&z=16&t=m&hl=en&gl=US&mapclient=embed&daddr=UCLA+Ackerman+Union+308+Westwood+Plaza+Los+Angeles,+CA+90024@34.070418,-118.4441451"> here </a>
              </div>
            </div>

             <div className="templatemain-options">
               <div className="templatemain-options-item">
                   <img src={carImage} alt=""/>
                 <div className="templatemain-options-item-title">
                   {"Directions".toUpperCase()}
                   </div>
                   <div className="templatemain-options-content">
               <br/>
               1. Take the 405 to Sunset Boulevard 
               <br/>
               <br/>
                ~ Parking Structure 4  ~
                221 Westwood Plaza, Los Angeles
               <br/>
               <br/>
                ~ Parking Structure 8 ~
                555 Westwood Plaza, Los Angeles
                   </div>
               </div>


               <div className="templatemain-options-item">
                   <img src={utensilsImage} alt=""/>
                 <div className="templatemain-options-item-title">
                   {"Nearby Food Places".toUpperCase()}
                 </div>

                   <div className="templatemain-options-content">
                      <div className="templatemain-food-list-wrapper">
                       <ul className="templatemain-food-list">
                       {
                         foodOptionList.map((option)=>{ return <li > {option} </li>;})
                       }
                       </ul>
                       <ul className="templatemain-food-list">
                       {
                         foodOptionListTwo.map((option)=>{ return <li > {option} </li>;})
                       }
                       </ul>
                       </div>
                       </div>
               </div>
             </div>
          </div>

  }
}

export default Location;
