import React, {Component} from "react";
import TemplateMain from '../template/TemplateMain';
import './Contact.css';
import teamImage from '../../images/team_orangeblossom.jpg';
import contactImage from '../../images/contactimage.jpg';
// import email from '../images/logoemail.svg';


class Contact extends React.Component {
  render (){
    return <div className="">
      <TemplateMain data={templateInfo}/>
      </div>
  }
}



const templateInfo = {
  dontRenderBottom: true,
    title: "Contact",
    renderContent: ()=>{ return <div className="menu-top">
        <img src={contactImage} style={{"margin": "0 auto", "height": "auto", "width": "400px", "marginBottom":"20px"}} alt=""/>
        <div className="item-contact">
          Questions? Concerns? Reach out! <br/>
          Message us on Facebook <a href="https://www.facebook.com/groups/1396610513958430/?ref=bookmarks"> here! </a> <br/>
          <br/>
          Or, feel free to email us at <span style={{"textDecoration":"underlined"}}> dancesport@ucla.edu</span>
        </div>
        </div>
},

    renderContentBottom: ()=>{
      return null;
    //   <div>
    //   <div className="menu-container">
    //    <div className="menu">
    //     <form className="form">
    //       <input className="item-half-left" type="text" name="name"    placeholder="Name"/>
    //       <input className="item-half-right" type="text" name="email"   placeholder="Email Address"/>
    //       <br/>
    //       <input className="item-full" type="text" name="subject" placeholder="Subject"/>
    //       <br/>
    //       <input className="item-full-message"  type="text" name="message" placeholder="Write your message here..."/>
    //       <input className="item-send" type="submit" name="enter" value="Send"/>
    // </form>
    //   </div>
    //   </div>
    //   </div>
    }
};


      // <img src={teamImage} style={{margin: "0 auto", height: "300px", width: "400px"}} alt=""/>

export default Contact;
