import React, {Component} from "react";
import TemplateMain from '../template/TemplateMain';
import './Donation.css';
import latinDancerImage from '../../images/latin.jpg';


class Donation extends React.Component {
  render (){
    return <div className="">
          <TemplateMain data={templateInfo}/>
    </div>

  }
}
const templateInfo = {
  title: "Donation",
  renderContent: ()=>{ return <div className="donation-content">
        <div className="donation-info">
        </div>
          <strong> Special Thanks to Our Current Lovely Sponsors!</strong>
          <br/>
          Zhou Family - $100 <strong> Silver Sponsor </strong>

          <div style={{"width": "100%", "textAlign": "center", "padding": "1% 25%" }}>
          Sunset Classic is hosted by the Dancesport Club at UCLA.
           We are entirely student run, and support for this competition comes from wonderful sponsors like you.
           Without you hosting our competition would not be possible!
           Please consider donating $5, $10, or $20 today to help this competition happen!
           You valuable donation enables us to hire the most qualified judges.
           <br/>
            <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input type="hidden" name="hosted_button_id" value="BLZ94FLVQ544J" />
              <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
              <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
            </form>
           </div>

           <div style={{"color":"black", "padding":"5% 35%"}}>
             <div style={{"backgroundColor": "white", "padding": "5px 30px", "textAlign":"left"}}>
             <div style={{"backgroundColor": "white", "padding": "5px 70px", "textAlign":"left"}}>
               <br/> $50	Newcomer Sponsor
               <br/> $75	Bronze Sponsor
               <br/> $100 Silver Sponsor
               <br/> $150 Gold Sponsor
               <br/> $200 Diamond Sponsor
               <br/> $500	Founding Sponsor ("Help us get off the ground -- be part of an exclusive group of donors who are listed in every program from here on out")

               <br/>
               <br/>
               <br/>
               </div>
             </div>


           </div>

          <br/>

         </div>
  },
  renderContentBottom: ()=>{
    return <div></div>
  }
}

export default Donation;


// Sunset Classic is hosted by the Dancesport Club at UCLA. We are entirely student run, and support for this competition comes from wonderful sponsors like you. Without you hosting our competition would not be possible!
// Please consider donating $5, $10, or $20 today to help this competition happen! You valuable donation enables us to hire the most qualified judges.
// In appreciation for your donation, gifts of over $50 will be recognized on this page as well as other wonderful appreciation benefits! Please contact us at (LINK to FB) for questions about the Donation tiers and benefits.
//
// $50	Listed as "Newcomer Sponsor" on Website
// $75	Listed as "Bronze Sponsor" on Website
// $100	Listed as "Silver Sponsor" on Website
// $150	Listed as "Gold Sponsor" on Website
// $200	Listed as "Diamond Sponsor" on Website
// $500	Founding Sponsor ("Help us get off the ground -- be part of an exclusive group of donors who are listed in every program from here on out")

// Acknowledgement Box
// 200 from the johnson family


// Excel sheet -----> Link todo:
