import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import { Parallax } from 'react-parallax';
import './SplashPage.css';
import './Ribbon.css';
import bgImage from '../../images/splashpage.svg';
import bearBg from '../../images/bear.svg';
import sectionBreakImage from '../../images/sectionbreak.svg';
import sign from '../../images/sunsetsign.svg';
import  Background from '../../images/splashpage.svg';
import  ScrollDown from '../../images/scrolldown.png';

let siteBackgroundStyle = {
  width: "100%",
  height: "1000px",
  backgroundImage: `url(${Background})`,
  backgroundSize: "cover"
};

class SplashPage extends React.Component {
  render() {
    return (
      <div className="splash-master-bg">
      <Parallax
   style={{"padding":"0","margin":"0"}}
    bgImageStyle={{"width": "100%", "height": "1200px", "backgroundSize": "contain",
    "backgroundColor": "#40858C"
  }}
     contentClassName="splash-parallax"
     bgImage={Background}
     bgImageAlt="the cat"
     strength={400}
     >

      <div class="content">
        <div class="menu-space"></div>
        <div class="menu-container">
          <div class="menu">

   <p> <span className="splash-one" style={{"fontFamily": "Lato !important"}}> Welcome to the
    </span> </p>
     <p> <span className="splash-two"> Sunset Classic Dance Competition!</span> </p>

      <div class="ribbon"> Feb. 15th, 2020 <i></i> <i></i> <i></i> <i></i> </div>

            <span className="splash-subtext">
            </span>
            <span
            style={{
              "color": "white",
               "fontWeight":"bold",
               "fontSize":"0.8em",
                "textTransform":"none",
              "textAlign": "center"}}>
              Hosted by Dancesport Club at UCLA and <br/> SC Ballroom and Latin Team
              <br/>
              at the beautiful Grand Ackerman Ballroom
              <br/>

                  <span style={{"textDecoration": "underline"}} > Learn More Below! </span>
                  <br/>
                  <br/>
                  <img src={ScrollDown} style={{"width": "40px", "height":"auto"}}/>
                   </span>
              {svgSign}
          </div>
        </div>
      </div>
       </Parallax>

       <Parallax
            style={{"padding":"0","margin":"0", "height":"100%"}}
             bgImageStyle={{width: "100%",
             height: "100%",
             backgroundColor: "#0E1030"
           }}
              contentClassName="splash-parallax"
              bgImage={bearBg}
              bgImageAlt="the cat"
              strength={200}
              >

              <img className="bear-invisible" src={bearBg} alt=""/>
              <div class="content-second">
              <div className="content-precursor">
              Sunset Classic is
              a Collegiate Dancesport Association
              Circuit Competition, UCLA's first live ballroom competition ever.
              <br/>
              The venue will be the beautiful Ackerman Grand Ballroom.

              </div>
              <br/>
              <img className="content-break" src={sectionBreakImage}
              style={{"display":"block", "margin": "0 auto",
                 "margin-bottom":"140px",
               "textAlign": "center"}} alt=""/>
              <div className="content-second-body">

                <div className="bear-grid">
                  <div className="bear-left"> With Support from our Sponsors </div>
                  <div className="bear-middle"> Featuring the largest collegiate judging panel in Southern California... </div>
                  <div className="bear-right">
               From sunrise to sunset, come spend some time with us.
                              </div>
              </div>
              </div>
              </div>
               </Parallax>
               </div>
    )
  }
}

const svgSign = () =>
  <svg width="368" height="309" viewBox="0 0 368 309" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M26.0062 158.32L101.025 127.63V108.307L116.938 98.0775V81.0278L152.174 63.9781C152.174 63.9781 152.174 18.5122 186.273 3.73585C216.963 -9.55154 220.373 29.8787 220.373 29.8787L261.292 11.6924V31.0154L276.068 24.1955V44.6551L367 2.5992V92.3942L26.0062 239.022V158.32Z" fill="#16272D" stroke="#16272D" stroke-miterlimit="10"/>
  <g opacity="0.7">
  <path opacity="0.3" d="M68.0621 166.276L62.3789 169.686C62.3789 169.686 62.3789 164.003 57.8323 165.14C53.2857 166.276 54.4224 175.369 54.4224 175.369C54.4224 175.369 55.0248 179.2 64.6522 183.326C72.6087 186.736 70.3354 212.879 58.9689 216.289C58.9689 216.289 45.3292 220.835 45.3292 203.786L53.2857 201.512C53.2857 201.512 51.4216 210.446 56.6957 208.332C62.3789 206.059 63.5155 199.239 63.5155 199.239C63.5155 199.239 64.6522 189.009 56.6957 187.872C49.8758 186.895 48.7391 182.189 48.7391 182.189C48.7391 182.189 45.3292 167.413 54.4224 160.593C63.5155 153.773 66.9255 160.593 66.9255 160.593L68.0621 166.276Z" stroke="#3E858B" stroke-width="1.5" stroke-miterlimit="10"/>
  <path opacity="0.3" d="M73.7453 152.636L80.5652 149.227L81.7019 193.556C81.7019 193.556 81.5314 198.852 86.2484 196.966C91.9317 194.692 91.9317 190.146 91.9317 190.146V144.68L97.6149 141.27V190.146C97.6149 190.146 95.3416 203.786 85.1118 204.922C77.1553 205.809 76.0186 199.239 76.0186 199.239L73.7453 152.636Z" stroke="#3E858B" stroke-width="1.5" stroke-miterlimit="10"/>
  <path opacity="0.3" d="M104.435 194.692V138.997L110.118 136.723L121.484 164.003V132.177L128.304 128.767V183.326L123.758 185.599L111.255 156.046V191.282L104.435 194.692Z" stroke="#3E858B" stroke-width="1.5" stroke-miterlimit="10"/>
  <path opacity="0.3" d="M163.54 111.717L164.677 168.55L188.547 157.183V150.363L171.497 157.183V137.86L180.59 134.45V127.63L171.497 131.04V116.264L185.137 109.444V102.624L163.54 111.717Z" stroke="#3E858B" stroke-width="1.5" stroke-miterlimit="10"/>
  <path opacity="0.3" d="M189.683 100.351V106.034L198.776 102.624V152.636L205.596 149.227L204.46 100.351L212.416 96.9408V90.1209L189.683 100.351Z" stroke="#3E858B" stroke-width="1.5" stroke-miterlimit="10"/>
  <path opacity="0.3" d="M156.72 127.63L151.037 131.04C151.037 131.04 151.037 125.357 146.491 126.494C141.944 127.63 143.081 136.723 143.081 136.723C143.081 136.723 143.683 140.554 153.311 144.68C161.267 148.09 158.994 174.233 147.627 177.643C147.627 177.643 133.988 182.189 133.988 165.14L141.944 162.866C141.944 162.866 140.08 171.8 145.354 169.686C151.037 167.413 152.174 160.593 152.174 160.593C152.174 160.593 153.311 150.363 145.354 149.227C138.534 148.249 137.398 143.543 137.398 143.543C137.398 143.543 133.988 128.767 143.081 121.947C152.174 115.127 155.584 121.947 155.584 121.947L156.72 127.63Z" stroke="#3E858B" stroke-width="1.5" stroke-miterlimit="10"/>
  <path opacity="0.3" d="M273.795 60.5681L264.702 66.2514V60.5681L256.745 75.3445L266.975 84.4377V77.6178L273.795 74.2079V60.5681Z" stroke="#3E858B" stroke-width="1.5" stroke-miterlimit="10"/>
  <path opacity="0.3" d="M340.857 29.8787V43.5185L351.087 38.9719V42.3818L359.044 29.8787L349.95 21.9222V26.4688L340.857 29.8787Z" stroke="#3E858B" stroke-width="1.5" stroke-miterlimit="10"/>
  <path opacity="0.3" d="M278.342 52.6116L279.478 82.1644C279.149 82.5054 293.118 78.3453 293.118 67.388C293.118 60.5681 288.571 61.7048 288.571 61.7048C288.571 61.7048 291.981 59.4315 291.981 56.0216C291.981 51.475 290.845 48.065 287.435 48.065C284.889 48.065 278.342 52.6116 278.342 52.6116Z" stroke="#3E858B" stroke-width="1.5" stroke-miterlimit="10"/>
  <path opacity="0.3" d="M282.888 67.388V75.3445C282.888 75.3445 289.708 74.2079 288.571 67.388C287.435 63.9781 282.888 67.388 282.888 67.388Z" stroke="#3E858B" stroke-width="1.5" stroke-miterlimit="10"/>
  <path opacity="0.3" d="M282.888 53.7483V61.7048C282.888 61.7048 289.708 60.5681 288.571 53.7483C287.435 50.3383 282.888 53.7483 282.888 53.7483Z" stroke="#3E858B" stroke-width="1.5" stroke-miterlimit="10"/>
  <path opacity="0.3" d="M295.391 45.7917V74.2079L307.894 68.5247V63.9781L299.938 67.388V43.5185L295.391 45.7917Z" stroke="#3E858B" stroke-width="1.5" stroke-miterlimit="10"/>
  <path opacity="0.3" d="M305.621 41.2452L312.441 66.2514L316.988 63.9781L319.261 33.2886L314.714 36.6986L313.578 53.7483L310.168 38.9719L305.621 41.2452Z" stroke="#3E858B" stroke-width="1.5" stroke-miterlimit="10"/>
  <path opacity="0.3" d="M323.239 32.152V61.7048C323.239 61.7048 337.549 58.2949 335.163 37.8352C333.708 25.3549 323.239 32.152 323.239 32.152Z" stroke="#3E858B" stroke-width="1.5" stroke-miterlimit="10"/>
  <path opacity="0.3" d="M326.649 36.1303V54.3166C326.649 54.3166 333.321 52.2138 332.207 39.6311C331.525 31.9474 326.649 36.1303 326.649 36.1303Z" stroke="#3E858B" stroke-width="1.5" stroke-miterlimit="10"/>
  <path opacity="0.3" d="M271.522 126.494C271.522 126.494 278.342 115.127 278.342 111.717C274.932 113.991 274.932 115.127 271.522 113.991C268.112 112.854 266.975 103.761 268.112 99.2141C269.248 94.6675 273.897 88.6433 278.342 90.1209C281.752 91.2576 282.888 96.9408 282.888 96.9408C282.888 96.9408 284.025 104.897 282.888 110.581C282.183 114.104 274.932 129.904 274.932 129.904L271.522 126.494Z" stroke="#3E858B" stroke-width="1.5" stroke-miterlimit="10"/>
  <path opacity="0.3" d="M276.068 94.6675C276.068 94.6675 272.658 94.6675 271.522 101.487C270.772 105.977 271.522 109.444 273.795 109.444C274.553 109.49 275.313 109.375 276.024 109.106C276.734 108.837 277.38 108.42 277.917 107.883C278.454 107.345 278.871 106.7 279.14 105.989C279.41 105.279 279.525 104.519 279.478 103.761C279.478 92.3942 276.068 94.6675 276.068 94.6675Z" stroke="#3E858B" stroke-width="1.5" stroke-miterlimit="10"/>
  <path opacity="0.3" d="M298.881 84.2104C298.881 84.2104 292.061 95.5768 292.061 98.9868C295.471 96.7135 295.471 95.5768 298.881 96.7135C302.291 97.8501 303.427 106.943 302.291 111.49C301.154 116.036 296.505 122.061 292.061 120.583C288.651 119.446 287.514 113.763 287.514 113.763C287.514 113.763 286.378 105.807 287.514 100.123C288.219 96.5998 295.471 80.8004 295.471 80.8004L298.881 84.2104Z" stroke="#3E858B" stroke-width="1.5" stroke-miterlimit="10"/>
  <path opacity="0.3" d="M294.334 116.036C294.334 116.036 297.744 116.036 298.881 109.217C299.631 104.67 298.881 101.26 296.608 101.26C295.849 101.214 295.089 101.329 294.379 101.598C293.668 101.867 293.023 102.284 292.486 102.821C291.948 103.359 291.531 104.004 291.262 104.715C290.993 105.425 290.878 106.185 290.924 106.943C290.924 118.31 294.334 116.036 294.334 116.036Z" stroke="#3E858B" stroke-width="1.5" stroke-miterlimit="10"/>
  <path opacity="0.3" d="M314.714 71.9346C314.714 71.9346 304.484 75.3445 305.621 94.6675C306.758 113.991 312.441 111.717 312.441 111.717C312.441 111.717 323.807 112.854 323.807 91.2576C323.807 89.3404 323.739 87.6014 323.603 86.0404C322.262 70.0478 314.714 71.9346 314.714 71.9346Z" stroke="#3E858B" stroke-width="1.5" stroke-miterlimit="10"/>
  <path opacity="0.3" d="M314.737 78.1861C314.737 78.1861 309.054 80.5163 309.645 93.7127C310.236 106.909 313.464 105.466 313.464 105.466C313.464 105.466 319.829 106.239 319.829 91.4849C319.829 90.1778 319.829 88.9957 319.716 87.9272C318.965 76.9472 314.737 78.1861 314.737 78.1861Z" stroke="#3E858B" stroke-width="1.5" stroke-miterlimit="10"/>
  <path opacity="0.3" d="M336.311 62.8414C336.311 62.8414 326.081 66.2514 327.217 85.5744C328.354 104.897 334.037 102.624 334.037 102.624C334.037 102.624 345.404 103.761 345.404 82.1644C345.404 80.2473 345.336 78.5082 345.199 76.9472C343.858 60.9546 336.311 62.8414 336.311 62.8414Z" stroke="#3E858B" stroke-width="1.5" stroke-miterlimit="10"/>
  <path opacity="0.3" d="M336.333 69.093C336.333 69.093 330.65 71.4231 331.241 84.6196C331.832 97.816 335.06 96.3725 335.06 96.3725C335.06 96.3725 341.425 97.1454 341.425 82.3917C341.425 81.0846 341.425 79.9025 341.312 78.834C340.562 67.854 336.333 69.093 336.333 69.093Z" stroke="#3E858B" stroke-width="1.5" stroke-miterlimit="10"/>
  <path opacity="0.3" d="M207.87 15.1023L163.54 35.5619C163.54 35.5619 163.54 54.8849 169.224 60.5681C174.907 66.2514 185.137 71.9346 185.137 71.9346C185.137 71.9346 205.596 46.9284 206.733 37.8352C207.87 28.7421 207.87 15.1023 207.87 15.1023Z" stroke="#3E858B" stroke-width="1.5" stroke-miterlimit="10"/>
  <path opacity="0.3" d="M212.416 50.3383L232.876 40.1085" stroke="#3E858B" stroke-width="1.5" stroke-miterlimit="10"/>
  <path opacity="0.3" d="M160.13 73.0712L141.944 81.0278" stroke="#3E858B" stroke-width="1.5" stroke-miterlimit="10"/>
  <path opacity="0.3" d="M234.012 37.8352L237.422 33.2886L239.696 35.5619L236.286 40.1085L234.012 37.8352Z" stroke="#3E858B" stroke-width="1.5" stroke-miterlimit="10"/>
  <path opacity="0.3" d="M238.559 41.2452L241.969 36.6986L244.242 38.9719L240.832 43.5185L238.559 41.2452Z" stroke="#3E858B" stroke-width="1.5" stroke-miterlimit="10"/>
  <path opacity="0.3" d="M238.559 31.0153L241.969 26.4688L244.242 28.7421L240.832 33.2886L238.559 31.0153Z" stroke="#3E858B" stroke-width="1.5" stroke-miterlimit="10"/>
  <path opacity="0.3" d="M243.106 34.4253L246.516 29.8787L248.789 32.152L245.379 36.6986L243.106 34.4253Z" stroke="#3E858B" stroke-width="1.5" stroke-miterlimit="10"/>
  <path opacity="0.3" d="M131.714 82.1644L135.124 77.6178L137.398 79.8911L133.988 84.4377L131.714 82.1644Z" stroke="#3E858B" stroke-width="1.5" stroke-miterlimit="10"/>
  <path opacity="0.3" d="M130.578 91.2576L133.988 86.711L136.261 88.9843L132.851 93.5309L130.578 91.2576Z" stroke="#3E858B" stroke-width="1.5" stroke-miterlimit="10"/>
  <path opacity="0.3" d="M135.124 85.5744L138.534 81.0278L140.807 83.3011L137.397 87.8476L135.124 85.5744Z" stroke="#3E858B" stroke-width="1.5" stroke-miterlimit="10"/>
  <path opacity="0.3" d="M127.168 87.8476L130.578 83.3011L132.851 85.5744L129.441 90.1209L127.168 87.8476Z" stroke="#3E858B" stroke-width="1.5" stroke-miterlimit="10"/>
  </g>
  <path d="M17.4814 157.183C26.5838 157.183 33.9627 148.532 33.9627 137.86C33.9627 127.188 26.5838 118.537 17.4814 118.537C8.37896 118.537 1 127.188 1 137.86C1 148.532 8.37896 157.183 17.4814 157.183Z" fill="#16272D" stroke="#16272D" stroke-miterlimit="10"/>
  <path d="M25.4379 154.489C25.4379 149.781 21.6212 145.964 16.913 145.964C12.2049 145.964 8.38818 149.781 8.38818 154.489V666.98C8.38818 671.688 12.2049 675.505 16.913 675.505C21.6212 675.505 25.4379 671.688 25.4379 666.98V154.489Z" fill="#16272D" stroke="#16272D" stroke-miterlimit="10"/>
  <path opacity="0.3" d="M20.323 186.736V410.655" stroke="#3E858B" stroke-width="2" stroke-miterlimit="10"/>
  <path opacity="0.3" d="M28.0295 135.553C28.0295 135.553 26.2222 126.107 17.7314 124.254" stroke="#3E858B" stroke-width="2" stroke-miterlimit="10"/>
  </svg>;


export default SplashPage;
                  // <div className="bear-middle"> Just Off Sunset Boulevard... </div>
                      // At UCLA’s Inagural
                      // SunsetDance
                      // Competition
            // <span className="ribbon"
            // Hosted UCLA & USC <br/>
            // February 15, 2020 <br/>
//             <span style={{"color": "#266061"}}> February 15, 2020 <br/></span>
// <span style={{"color": "#266061"}}> Ackerman Grand Ballroom, University of California, Los Angeles </span>
