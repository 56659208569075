import React, {Component} from "react";
import TemplateMain from '../template/TemplateMain';
import './Faq.css';

class Faq extends React.Component {
  render (){
    return <div className="">
          <TemplateMain data={templateInfo}/>
      </div>
  }
}
const templateInfo = {
  title: "FAQ",
  renderContent: ()=>{ return <div></div>},
  renderContentBottom: ()=>{
    return null;
  }
};



export default Faq;
