import React, {Component} from "react";
import './TemplateMain.css';
import carImage from '../../images/car.svg';
import utensilsImage from '../../images/utensils.svg';
import splashBackground from '../../images/splashpage.svg'


class TemplateMain extends React.Component {

  render (){
    const {data} = this.props;
    return <div className="templatemain">
    <div className="templatemain-background-filler"> </div>
    <div className="templatemain-background-title"> {data.title}</div>
    <div className="templatemain-background-filler-bg">
    {data.renderContent()}
    </div>
    {(data.renderContentBottom()!=='undefined')?data.renderContentBottom():<div className="filler-bottom"></div>}
    </div>
  }
}

const foodOptionList = [
"Panda Express",
"Jamba Juice",
"Wolfgang Puck Express",
"Carl’s Jr.",
"Rubio’s",
"Veggie Grill"];
const foodOptionListTwo = [
"Taco Bell",
"Lollicup",
"Wetzel’s Pretzels",
"Bruin Buzz Coffee",
"Curbside",
"Greenhouse"
]
export default TemplateMain;
