import React, {Component} from "react";
import TemplateMain from '../template/TemplateMain';
import "./Judges.css";

class Judges extends React.Component {
  render(){
    return <div className="">
          <TemplateMain data={templateInfo}/>
      </div>
  }
};
const templateInfo = {
  title: "Judges",
  renderContent: ()=>{ return <div className="judges"> To Be Announced!  Thank you for your patience</div>},
  renderContentBottom: ()=>{
    return null;
  }
};
export default Judges;
