import React, { Component } from 'react';
import './Header.css';
import './Logo.css';
import { NavLink } from 'react-router-dom'
import logoImage from '../../images/logo.png';
import styled from 'styled-components'

import {Button, Navbar, Nav, NavDropdown, Form, FormControl} from 'react-bootstrap';

class Header extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isTop: true,
    }
  }

  componentDidMount() {
    document.addEventListener('scroll', () => {
      const isTop = window.scrollY < 150;
      if (isTop !== this.state.isTop) {
          this.setState({ isTop })
      }
    });
  }
  render() {
    return (
      <header className={this.state.isTop ? "" : "header-custom" } >
  <Navbar  className="navbar-custom "expand="lg">
<Navbar.Brand href="#home">
    <div id="logo" className="menuUp">
    <NavLink className="navLink" exact to="/home">
    <img className="header-logo" src={logoImage} alt=""/>
      <span className="header-logo-title"> Sunset Classic </span>
    </NavLink>
    </div>
</Navbar.Brand>
<Navbar.Toggle aria-controls="basic-navbar-nav" />
<Navbar.Collapse className="justify-content-center" style={{"paddingRight":"10%"}} id="basic-navbar-nav">
<Nav className="justify-content-center" >

    <Nav.Link style={{"marginRight":"15px", "marginLeft":"5px", "color": "white"}} href="#home">
    <NavLink className="navLink" exact to="/home">  Home </NavLink> </Nav.Link>

    <Nav.Link style={{"marginRight":"15px", "marginLeft":"5px", "color": "white"}} href="#register">
    <NavLink className="navLink" exact to="/register">  Register </NavLink> </Nav.Link>

  <NavDropdown  style={{"marginRight":"15px", "marginLeft":"5px", "color": "white !important"}} title="Details" id="basic-nav-dropdown">
    <NavLink className="navLink" exact to="/schedule">  <NavDropdown.Item href="#action/3.1">Schedule</NavDropdown.Item> </NavLink>
    <NavLink className="navLink" exact to="/location">  <NavDropdown.Item href="#action/3.2">Location</NavDropdown.Item> </NavLink>
    <NavLink className="navLink" exact to="/judges">  <NavDropdown.Item href="#action/3.3">Judges</NavDropdown.Item> </NavLink>
    <NavLink className="navLink" exact to="/rules">  <NavDropdown.Item href="#action/3.3">Rules</NavDropdown.Item> </NavLink>
  </NavDropdown>

  <Nav.Link style={{"marginRight":"15px", "marginLeft":"5px", "color": "white"}} href="#register">
  <NavLink className="navLink" exact to="/donation">  Donate </NavLink> </Nav.Link>

  <Nav.Link style={{"marginRight":"15px", "marginLeft":"5px", "color": "white"}} href="#register">
  <NavLink className="navLink" exact to="/contact"> Contact </NavLink> </Nav.Link>


</Nav>
</Navbar.Collapse>
</Navbar>
</header>
    )
  }
}

export default Header;

    // <li><a href="#"> <NavLink className="navLink" exact to="/faq"> FAQ </NavLink> </a></li>
