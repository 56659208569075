import React, {Component} from "react";
import TemplateMain from '../template/TemplateMain';
import './Register.css';
import latinDancerImage from '../../images/latin.jpg';
import balletDancerImage from '../../images/balletdancer.jpg';
import salsaDancerImage from '../../images/salsa.jpeg';
import nightClubImage from '../../images/fadedNightClub.jpg';
import spectatorDanceImage from '../../images/spectator.jpg';

class Register extends React.Component {

  render (){


    return <div className="">
          <TemplateMain data={templateInfo}/>
  </div>
  }
}

const registerItems = [
  {
    title: "NightClub",
    info: "Early Ticket date to be announced",
    image: nightClubImage,
    buttonLink: "google.com",
    buttonText: "Buy Tickets",
    baseStyle: {
     "backgroundColor": "#244043",
     "color": "white"
    }
  },
  {
    title: "Ballroom",
    info: "Early Registration date to be announced",
    image: latinDancerImage,
    buttonLink: "google.com",
    buttonText: "Register Now",
    baseStyle: {
     "backgroundColor": "#244043",
     "color": "white"
    }
  },
  {
    title: "Spectators",
    info: "Early Registration Tickets to be announced",
    image: spectatorDanceImage,
    buttonLink: "google.com",
    buttonText: "Register Now",
    baseStyle: {
     "backgroundColor": "#244043",
     "color": "white"
    }
  }
];


const templateInfo = {
  title: "Registration",
  renderContent: ()=>{
    const registerBlock = registerItems.map((registerItem)=>{
      return (
             <div className="register-options-item" style={registerItem.baseStyle}>
               <div className="register-options-item-title"> {registerItem.title} </div>
               <br/>
                    <div className="register-image-wrapper">
                      <img className="register-image" src={registerItem.image} alt="image dancer"></img>
                      {registerItem.info}
                  </div>
             </div>
      )
    });

    return     <div className="register-content">
          <div className="register-info"> Early Registration date will be released soon!
          <br/> Subscribe  <a href="https://ucla.us5.list-manage.com/subscribe?u=f15b63005e3c1424c42c8647b&id=1582e3335f"> here </a>
          to be notified when its up! </div>
          <div className="register-options"> {registerBlock} </div>
    </div>

  },
  renderContentBottom: ()=>{

  }
}
export default Register;
                // <button id="registerButton"> {registerItem.buttonText} </button>
                // buttons
                // <div className="register-subscribe"> Want to stay in the loop? Click <a href="google.com"> here </a> to subscribe</div>
//
