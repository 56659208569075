import React from 'react';
import logo from './logo.svg';
import Contact from './component/contact/Contact';
import Footer from './component/Footer';
import Faq from './component/faq/Faq';
import Header from './component/header/Header';
import Judges from './component/judges/Judges';
import Rules from './component/rules/Rules';
import Register from './component/register/Register';
import SplashPage from './component/splashpage/SplashPage';
import Schedule from './component/schedule/Schedule';
import Location from './component/location/Location';
import Donation from './component/donation/Donation';


import './App.css';
import { BrowserRouter } from 'react-router-dom'
import { Route } from 'react-router-dom'
import  Background from './images/splashpage.svg';
var siteBackgroundStyle = {
  width: "100%",
  height: "1000px",
  backgroundImage: `url(${Background})`,
  backgroundSize: "cover"
};

function App() {
  return (
    <BrowserRouter>
    <div className="App" >
      <Header/>
        <Route exact path="/" exact component={SplashPage} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/footer" component={Footer} />
        <Route exact path="/faq" component={Faq} />
        <Route exact path="/home" component={SplashPage} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/rules" component={Rules} />
        <Route exact path="/schedule" component={Schedule} />
        <Route exact path="/location" component={Location} />
        <Route exact path="/donation" component={Donation} />
        <Route exact path="/judges" component={Judges} />
      <Footer />
    </div>
    </BrowserRouter>
  );
}
export default App;
