import React, {Component} from "react";
import TemplateMain from '../template/TemplateMain';
import "./Schedule.css";
import darkSchedBlock from '../../images/darkschedblock.svg';
import lightSchedBlock from '../../images/lightschedblock.svg';
import flourish from '../../images/flourish.svg';

class Schedule extends React.Component {
  render(){

    return (<div className="">
      <TemplateMain data={templateInfo}/>

      </div>
    );
  }
}

var danceJson = [
  { name: "Smooth", from: "8:00 AM", to: "10:00 AM" },
  { name: "Standard", from: "10:00 AM", to: "12:00 PM" },
  { name: "Lunch", from: "12:00 PM", to: "12:30 PM" },
  { name: "Rhythm", from: "12:30 PM", to: "2:30 PM" },
  { name: "Latin", from: "2:30 PM", to: "4:30 PM" },
  { name: "Dinner", from: "4:30 PM", to: "5:30 PM" },
  { name: "NightClub/Salsa", from: "5:30 PM", to: "7:00 PM" },
  { name: "Open", from: "7:00 PM", to: "10:00 PM" }
];


const templateInfo = {
    title: "Schedule",
    renderContent: ()=>{

      let i = 0;
      let lightBlueBg={"backgroundColor": "#3E848B", "color": "white"};

      var scheduleDisplay = danceJson.map((danceInfo)=>{
        i++;
        const {name, from, to} = danceInfo;
        let altRowStyle= (i%2==0)?lightBlueBg:{};
        return (<div className="schedule-item-multi">
            <div className="schedule-item-mult-left" style={altRowStyle}>
             {from} <br/>
             {to}
            </div>
            <div className="schedule-item-mult-right" style={altRowStyle}>
            {name.toUpperCase()}
            </div>
            </div>);
      });

      return <div>
      <div className="schedule-content">
           <div className="schedule-note">
             <div className="schedule-note-title">
             <span><img className="flourish" src={flourish} alt=""/></span>
             NOTES
             <span><img className="flourish" src={flourish} style={{transform: "scale(-1,1)"}}alt=""/></span>
             </div>
             <div className="schedule-note-content">
               <div className="schedule-title-info">
               *Tentative Schedule as of 10-19-19.
               <br/>
               A more detailed schedule will be posted 3 days before competition.
               <br/>
               Check live updates on our twitter: <a className="twitter-link" href="https://twitter.com/dancesportucla"> here </a>
               <br/>
               <br/>
               <br/>
               </div>
             Please be in the ballroom ready to go at least 30 minutes before your event.
             <br/>
             <br/>
             <br/>
             On the day-of, tune in to the competiiton with our Live Stream! We will be streaming during the full day of the competition. Link will be available on this page and on all our social media.
             </div>
           </div>

          <div className="schedule-table" > {scheduleDisplay} </div>;

        </div>
      </div> },

    renderContentBottom: ()=>{
    }
};

export default Schedule;
