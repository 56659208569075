import React, {Component} from "react";
import TemplateMain from '../template/TemplateMain';
import './Rules.css';
const ReactMarkdown = require('react-markdown/with-html');
// TODO: FIX

// This block of Markdown contains <a href="https://en.wikipedia.org/wiki/HTML">HTML</a>, and will require the <code>html-parser</code> AST plugin to be loaded, in addition to setting the <code class="prop">escapeHtml</code> property to false.
const markdown = `
<div style='color: #171A2D; font-style: italic;'>
By entering to participate in the Sunset Classic competition at UCLA, you are agreeing to adhere to the following:
</div>

<br/>
<div style='font-size: 30px;font-weight:bold;margin-bottom:20px;'>Costumes</div>

* Costumes are defined according to the Collegiate Dancesport Association costume guidelines (http://www.collegiatedancesport.org/costumes/) including:
Jackets of any kind, including tail suits, tuxedo jackets, and off-the-rack suit jackets, constitute a costume.
* Floats, streamers, scarves, cords hanging from sleeves, sashes, or any other non- structural material that flows behind while a dancer is in motion constitutes a costume.
* Shirts or tops that are open to below the breastbone constitute a costume.
* Skirts or dresses with more than two layers in the skirting constitute a costume.
* Any article of clothing incorporating rhinestones, gemstones, sequins, or actual lights constitutes a costume. “Articles of clothing” include shirts, pants, vests, skirts, socks, ties, pocket squares, belts, suspenders, etc.)
* In additional to the CDA guidelines, the following restrictions will be enforced:
* Additional Restriction: The use of feathering, glitter, metallic fabric, metallic threading, and related decorative effects constitutes a costume when more than 5% (cumulative) of the area of the article of clothing is covered by these effects.
* Additional Restriction: The use of fringing constitutes a costume when more than 5% of the total area of all clothing is covered by fringe.

* Further costume notes:
Jewelry items are excluded from these costume guidelines as distinct from articles of clothing, may include stoning, etc. These include: rings, necklaces, belt buckles, tie pins & clips, bracelets, earrings, other piercings, hair pieces, cufflinks, watches, and lapel pins.
Shoes are excluded from these costume guidelines since they present an unavoidable expense regardless of their appearance. Nevertheless, dancers are expected to honor the spirit of costuming restrictions and not augment (e.g. add stoning to) shoes for levels of competition in which costuming restrictions apply.
Dresses and Skirts: Please be sure to wear dance shorts under your dress or skirt. Dance shorts must a color other than nude; black or the same color as the dress or skirt are recommended.
The Deck Captain reserves the right to instruct any dancer to change into acceptable clothing or cover the costume effects with a jacket and will be considered the final voice on such matters.
Further, CDA recognizes that case-by-case judgments may need to be made and recommends that competition organizers provide for such adjudication. If you’re not sure, come ask at the Head Table.
<br/>
<br/>
<div style='font-size: 30px;font-weight:bold;margin-bottom:20px'>Syllabus</div>


* Sunset Classic is a Fair Level (Link: http://www.collegiatedancesport.org/fairlevel/) competition. By competing, you agree to earn Proficiency Points in accordance with CDA Fair Level guidelines.
* Levels are consecutive from Newcomer through Open as follows, from beginning to advanced: Newcomer, Bronze, Silver, Gold, Novice, Pre-Championship, Championship
* The Newcomer level is for competitors who have been dancing for six months or less, and started competing no earlier than Fall, 2019. Both partners in the couple must be eligible in order to compete at the Newcomer Level. Newcomer level dancers are restricted to Bronze syllabus steps. If you register for Newcomer and no longer qualify, your registration will be adjusted.
* Newcomer through Silver are closed syllabus events; costumes are not permitted.
* Gold are closed syllabus events; costumes are optional.
* Rookies are defined as Newcomer/Bronze and Bronze/Silver level dancers. Veterans are defined as Silver/Gold and above level dancers. Costumes are optional.
* Novice through Open are open syllabus; costumes are optional.
* Each competitor may only dance one level per dance in the nightclub category. The Beginner level is reserved for dancers with less than 2 years dance experience.
* Each competitor may dance in the level appropriate for their skill level and one level higher within the same dance style regardless of lead or follow (Example: Bronze competitor may dance Silver, Gold competitor may dance Novice, Pre-Champ competitor may dance Champ). NOTE: Competitors may not compete in two levels of closed syllabus and two levels of open syllabus.
* Both dancers in a partnership must be eligible to dance at the same level – regardless of lead or follow. Specifically, if the members of a partnership are at different levels then the higher of the two levels is considered to be the level of the couple. The only exception is if the level of the two dancers is more than two levels apart, then the partnership may compete at one level below the higher of the two levels. (Example: Bronze Lead with an Pre-Champ Follow may dance Novice and Pre   -Champ. However, a Gold lead with a Silver follow may dance Gold and Novice, not Silver.)

Sunset Classic will invigilate in accordance with the following syllabi for closed syllabus events:
Latin and Standard: ISTD, NDCA or DVIDA
Smooth and Rhythm: USA Dance, NDCA or DVIDA

Additionally, the following moves are strictly forbidden for all closed and open level events: Heel Slides, Lifts, and Drops (including any moves where one partner is not in at least partial support of their own weight)

<br/>
<br/>
<div style='font-size: 30px;font-weight:bold;margin-bottom:20px'>Additional Rules & Regulations</div>

* Entries postmarked after the registration deadline will be subject to a $10 late fee per competitor.
* Photography and videography are allowed.
* All dancers must have non-marking, suede-soled shoes while on the dance floor. High-heeled shoes must have heel protectors or suede stars.
* In order to compete in a multi-dance event, you must dance all dances in the event wthl the same partner.
* Add-Drop forms will be available at the registration desk on the day of Sunset Classic. Please note that adds will only be accommodated if there is room in the event and that the add does not change the number of rounds that the event has. For example, we cannot change an event from a semi-final to a quarter-final.
* Competitors should be ready at least 30 minutes prior to their first scheduled event.
UC Los Angeles, Associated Students at UCLA, Dancesport Club at UCLA, and our sponsors are not responsible for items left on the premises or for personal injuries/death.

`;


class Rules extends React.Component {
  render (){

    return <div className="">
          <TemplateMain data={templateInfo}/>

      </div>
  }
}

const rulesText = [
  {
      title:  "Costume",
      info: [
        "Costumes are permitted only in Gold and above for all dance styles, and in all levels for nightclub/salsa.",
      "Costumes are defined by the Collegiate Dancesport Association costume guidelines available here, with one exception: an outfit will be classified a costume if it contains gems, light effects, feathers, fringe or other decorations on more than 5 percent of the outfit.",
      "*Additionally, the deck captain reserves the right to instruct any dancer to change into acceptable clothing and will be considered the final voice on such matters.",
      " NOTE: Heel protectors are required."
    ]
  },
  {
      title: "Syllabus",
      info: [
        "Latin & Standard: NDCA",
        "Rhythm & Smooth: USA Dance"
        ]
    },
    {
      title: "Fair Level",
      info: [
        "All entries will be checked against the Collegiate Dancesport Association Database and all points earned at this competition will count towards your Fair-Level profile.",
        "All competitors are permitted to enter at their Fair Level and one level higher, as outlined by the CDA Fair-Level system."
      ]
    },
    {
      title: "Additional Dance Restrictions",
      info: [
        "In all dance styles, including nightclub/salsa, and at all levels (including open) the following moves are strictly forbidden: Heel Slides, Lifts and Drops (including any moves where one partner is not in at least partial support of their own weight)"
      ]
    }
];

const templateInfo = {
  title: "Rules",
  renderContent: ()=>{
    // let ruleHtml = rulesText.map((rule)=>{
    //       return (<div className="rules-item-wrapper">
    //         <div className="rules-title">{rule.title.toUpperCase()}</div>
    //         <ul>
    //           {
    //             rule.info.map(sentence=>(<li className="rule-sentence"> {sentence} </li>))
    //           }
    //           </ul>
    //
    //       </div>
    //     );
    // });
     return   <div className="white-bg-wrapper">
     <div className="white-bg">
     <ReactMarkdown
       source={markdown}
       escapeHtml={false}
     />
     </div>
      </div>},
  renderContentBottom: ()=>{
    return   <div></div>
  }
}
export default Rules;
// <div className="rules-header"> Rule & Regulations </div>
// <div className="rules-toc">
//         TABLE OF CONTENTS <br/>
//         I. COSTUMES<br/>
//         II. SYLLABUS<br/>
//         III. FAIR DANCE LEVEL <br/>
//         IV. ADDITIONAL DANCE RESTRICTIONS<br/>
// </div>
