import React, { Component } from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { NavLink } from 'react-router-dom'
import './Footer.css';
import facebook from '../images/logofacebook.svg';
import instagram from '../images/logoinstagram.svg';
import twitter from '../images/logotwitter.svg';
import email from '../images/logoemail.svg';

class Footer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      value: 'dancesport@ucla.edu', // TODO: CHANGE
      copied: false,
      // sectionsAreHidden: false
    };
  }
  render(){
    return ( <footer className="footer-grid-wrapper">
        <div className="main-grid">
          <div className="logo">Sunset Classic</div>
          Stay in the loop! Get up-to-date content like schedules
          and sneak peaks.
          <form action="submit">
              <a href="http://eepurl.com/gIKkM5"><input className="footer-input" id="footer-submit" type="text" placeholder="Subscribe"/></a>
          </form>
          <div className="footer-icon-row">
            <a href="https://www.facebook.com/groups/1396610513958430/?ref=bookmarks"> <img className="favIcons" src={facebook}/></a>
            <a href="https://www.instagram.com/dancesportclubucla/?hl=en"> <img className="favIcons" src={instagram}/></a>
            <a href="https://twitter.com/dancesportucla"> <img className="favIcons" src={twitter}/></a>
            <CopyToClipboard text={this.state.value} onCopy={() => this.setState({copied: true})}>
             <img className="favIcons" src={email}/>
              </CopyToClipboard>
            {this.state.copied ? <span style={{color: 'red', position: 'absolute'}}>Contact Email copied to your clipboard!</span> : null}

          </div>
        </div>
        {
          sections.map((section)=>{
            return <div style={(false)?{display:"none"}:{}} className={section.style}>
            <strong> {section.title} </strong>
                { section.data }
             </div>
          })
        }
    </footer> );
  }
}


const resourcesList = [
  {name: 'Contact', link: '/contact', navLink: true},
  {name: 'Rules and Regulations', link: '/rules', navLink: true},
  {name: 'Schedule', link: '/schedule', navLink: true},
  {name: 'Location', link: '/location', navLink: true},
  // {name: 'FAQ', link: 'sth'},
  {name: 'Judges', link: '/judges', navLink: true},
  {name: 'NCDA Syllabus', link: 'http://www.wright-house.com/dance/ndca-american-rhythm-syllabus-ballroom-dance.html', navLink: false}, // TODO: Add link
  {name: 'USA Dance Syllabus ', link: 'https://usadance.org/page/Rules', navLink: false
} // TODO: Add link
];
const supportList= [
  // {name: 'Live-Stream', link: '/'},
  {name: 'Donate', link: '/donation' , navLink: true},
  {name: 'DCU Facebook', link: 'https://www.facebook.com/dancesportucla', navLink:false}, // TODO: Add
  {name: 'DCU Instagram', link: 'https://www.instagram.com/dancesportclubucla/?hl=en', navLink: false},
  {name: 'SC Ballroom Facebook', link: 'https://www.facebook.com/USCBallroom', navLink: false}
];

const aboutList= [
  'Sunset Classic is UCLA\'\s premier Collegiate',
  'Dancesport Competition hosted by',
  'Dancesport Club at UCLA and USC Ballroom in',
  'association with the Collegiate Dancesport Association'
];

const {resourcesListFormatted, supportListFormatted, aboutListFormatted}  =
{
  resourcesListFormatted: resourcesList.map((str)=>{ return (str.navLink)?(<div className="sub-list-item">
  <NavLink exact to={str.link}> {str.name} </NavLink>
   </div>) : (<a className="sub-list-item" href={str.link}> {str.name} <br/></a>)
 }),
  supportListFormatted: supportList.map((str)=>{ return (str.navLink)?(<div className="sub-list-item">
  <NavLink exact to={str.link}> {str.name} </NavLink>
   </div>)    : (<a className="sub-list-item" href={str.link}> {str.name} <br/></a>)
 }),
  aboutListFormatted: aboutList.map((str)=>{ return (<div className="sub-list-item"> {str} </div>);})
};

const sections = [
  {
    title: "Resources ",
    style: "sub-grid",
    data: resourcesListFormatted
  },
  {
    title: "",
    title: "Show your support",
    style: "sub-grid",
    data: supportListFormatted
  },
  {
    title: "",
    title: "About Us",
    style: "sub-grid-right-corner",
    data: aboutListFormatted
  },
];


export default Footer;
